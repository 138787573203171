// import '../../styles/pages/question.scss';
import 'react-toastify/dist/ReactToastify.css';

import React, {memo, useState} from 'react';

import {
    LoadingGif,
    ApplePay,
    GPay,
    visaPay,
    masterCardPay,
    GrommetPay,
    StripeImage2
} from "../../images";

const QType13 = ({price}) => {

    // const [isLoading, setLoading] = useState(true);

    // setTimeout(function(){
    //     setLoading(false)
    // }, 300)

    // if(isLoading){
    //     return (
    //         <div className="loadWrap loadWrapQuiz loadWrapQuestion">
    //             <div className="loadWrapLogo">
    //                 <div className="loadWrapText">
    //                     <img width={16} src={LoadingGif} alt=""/>
    //                     {/* Loading.. */}
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }

    // const [imageStatus, setImageStatus] = useState(true);

    // if(imageStatus){
    //     return (
    //         <div>
    //             <img src={ApplePay} style={{display: 'none'}} />
    //             <img src={GPay} style={{display: 'none'}} />
    //             <img src={visaPay} style={{display: 'none'}} />
    //             <img src={masterCardPay} style={{display: 'none'}} />
    //             <img src={GrommetPay} style={{display: 'none'}} />
    //             <img src={StripeImage2} onLoad={()=> setImageStatus(false)} style={{display: 'none'}} />
    //         </div>
    //     )
    // }

    return (
        <>
            <div className='question active' >
                <div className="paypage">
                    {/* <p className="paypageTitle">Finish settings up your account</p> */}
                    {/* <p className="paypageText">Pay aslittle as 1.00 USD if you’re ready to get the ball rolling and start a 7-dyas trial</p>
                    <a href="" className="paypageMore">Show more</a> */}
                    <div className="paypagePrice">
                        <p>Special Price</p>
                        <div className="paypagePriceRow">
                            <b>${price}</b>
                            <span>USD</span>
                        </div>
                    </div>
                    <div className="paypageMethods">
                        <a href="">
                            <img src={ApplePay} alt="" />
                        </a>
                        <a href="">
                            <img src={GPay} alt="" />
                        </a>
                    </div>
                    <div className="paypageSystem">
                        <p>Set up your card:</p>
                        <img src={visaPay} alt="" />
                        <img src={masterCardPay} alt="" />
                        <img src={GrommetPay} alt="" />
                    </div>
                    <div className="paypageCard">
                        <div className="paypageNumber">
                            <label htmlFor="">Card Number</label>
                            <input type="text" placeholder="_ _ _ _     _ _ _ _     _ _ _ _     _ _ _ _" />
                        </div> 
                        <div className="paypageExpire">
                            <label htmlFor="">mm/yy</label>
                            <input type="text" placeholder="-- / --" />
                        </div>
                        <div className="paypageCVV">
                        <label htmlFor="">CVV2/CVC2</label>
                        <input type="text" placeholder="***" />
                        </div>
                    </div>
                    <div className="paypageStripe">
                        <img src={StripeImage2} alt="" />
                        <span>Your information is 100% secure. <br /> The payment is processed by Stripe</span>
                    </div>
                </div>
            </div>
        </>     
    )
}

export default memo(QType13);

import '../styles/global.scss';
import '../styles/pages/question.scss';

import QType1 from '../components/QuestionTypes/type1.tsx';
import QType2 from '../components/QuestionTypes/type2.tsx';
import QType3 from '../components/QuestionTypes/type3.tsx';
import QType4 from '../components/QuestionTypes/type4.tsx';
import QType5 from '../components/QuestionTypes/type5.tsx';
import QType6 from '../components/QuestionTypes/type6.tsx';
import QType7 from '../components/QuestionTypes/type7.tsx';
import QType8 from '../components/QuestionTypes/type8.tsx';
import QType9 from '../components/QuestionTypes/type9.tsx';
import QType10 from '../components/QuestionTypes/type10.tsx';
import QType11 from '../components/QuestionTypes/type11.tsx';
import QType12 from '../components/QuestionTypes/type12.tsx';
import QType13 from '../components/QuestionTypes/type13.tsx';
import QType14 from '../components/QuestionTypes/type14.tsx';
import QType15 from '../components/QuestionTypes/type15.tsx';
import QType16 from '../components/QuestionTypes/type16.tsx';
import QType17 from '../components/QuestionTypes/type17.tsx';
import QType18 from '../components/QuestionTypes/type18.tsx';
import QType19 from '../components/QuestionTypes/type19.tsx';
import QType20 from '../components/QuestionTypes/type20.tsx';
import QType21 from '../components/QuestionTypes/type21.tsx';

import {HeadFC, navigate} from "gatsby";
// import {fbq} from "@hutsoninc/gatsby-plugin-facebook-pixel";
import React, {useEffect, useState, useCallback, useRef} from 'react';

import {Link} from "gatsby";

import {
    qIcon1,
    qIcon2,
    qIcon3,
    Graph2,
    quesGraph1,
    quesGraph2,
    quesGraph3,
    quesGraph308,
    iphone,
    ArrowNext,
    DotBar,
    user101,
    user102,
    user103,
    user104,
    user105,
    user106,
    user107,
    user108,
    user109,
    user110,
    user111,
    user4,
    user4_2,
    user4_3,
    user5,
    user6,
    user7,
    user8,
    user9,
    user10,
    mountain,
    GraphStep,
    mQuesGraph,
    mGraph2,
    mQuesGraph2,
    mQuesGraph3,
    mGraph3,
    mGraph4,
    quesGraph4,
    ArrowPrev,
    rGraph,
    rGraph2,
    rGraph3,
    rGraph4
} from "../images";
import useAnalytics from "../hooks/useAnalytics";

type Props = {
    id: string
}

const Question = ({id}: Props) => {

    const {initAnalytics, saveEmail, saveSubType} = useAnalytics();

    useEffect(() => {
        initAnalytics();
    }, []);

    const [activeQues, setActiveQues] = useState(1);
    // const [btnStatus, setBtnStatus] = useState(false);
    // const [branch, setBranch] = useState(0);
    const [trialPrice, setTrialPrice] = useState('7.49');
    const [progressWidth, setProgressWidth] = useState(1);
    const [progressWidthSmall, setProgressWidthSmall] = useState(1);
    const [progressStatus, setProgressStatus] = useState('first');
    const [progressStatusSmall, setProgressStatusSmall] = useState('first');
    const [questions, setQuestions] = useState([
        {
            id: 1,
            progressSmall: 3,
            barEmpty: true,
            pageName: 'start-quiz',
            type: 'type1',
            name: "I want to get:",
            btnName: '',
            answers: [
                {
                    img: qIcon1,
                    name: 'Personal strategy for achieving goals',
                    branchNumber: 0,
                    nextStep: 'main-gp-goals',
                },
                {
                    img: qIcon2,
                    name: 'Personal financial growth strategy',
                    branchNumber: 1,
                    nextStep: 'main-fin-cr-goal',
                },
                {
                    img: qIcon3,
                    name: 'Skills development strategy',
                    branchNumber: 2,
                    nextStep: 'main-kd-skills',
                }
            ],
        },
        {
            id: 101,
            progressSmall: 7,
            bar: 'Tasks and Goals',
            pageName: 'main-gp-goals',
            nextStep: 'main-develop-gp-skills',
            type: 'type2',
            name: "What goals are you focused on?",
            btnName: '',
            answers: [
                'Financial goals',
                'Career goals',
                'Educational goals',
                'Social goals'
            ],
        },
        {
            id: 102,
            progressSmall: 10,
            bar: 'Tasks and Goals',
            pageName: 'main-develop-gp-skills',
            nextStep: 'main-how-many-gp-goals',
            type: 'type6',
            name: "We deliver results!",
            text: "You’ll start with small short term goals <br /> which will give you a sense of <br /> accomplishment and confidence.",
            descr: "These steps will help to <span>shape your future</span> and <br /> <span>form a habit</span>",
            subtitle2: '',
            image: Graph2,
            btnName: 'Сontinue',
        },
        {
            id: 103,
            progressSmall: 13,
            bar: 'Tasks and Goals',
            pageName: 'main-how-many-gp-goals',
            nextStep: 'main-goals-gp-achive',
            type: 'type2',
            name: "How many goals do you accomplish <br /> in a year?",
            btnName: '',
            answers: [
                '0-3',
                '3-5',
                '5-10',
                '10-20'
            ],
        },
        {
            id: 104,
            progressSmall: 17,
            bar: 'Tasks and Goals',
            pageName: 'main-goals-gp-achive',
            nextStep: 'main-gp-achive',
            type: 'type4',
            name: "On average, our users succeed <br /> <span>2.7 times</span> more goals <br /> than before using AEER",
            image: mQuesGraph2,
            btnName: 'Complete my goals too',
        },
        {
            id: 105,
            progressSmall: 20,
            bar: 'Tasks and Goals',
            pageName: 'main-gp-achive',
            nextStep: 'main-individual-gp-strategy',
            type: 'type2',
            name: "How many goals do you want to <br /> achieve in a year?",
            btnName: '',
            answers: [
                '3-5',
                '5-10',
                '10-20',
                '20-30'
            ],
        },
        {
            id: 106,
            progressSmall: 23,
            bar: 'A few more steps',
            pageName: 'main-individual-gp-strategy',
            nextStep: 'main-long-term-gp-goals',
            type: 'type5',
            name: "Our unique system will <span>create a <br /> personal strategy</span> for your education <br /> and smart goal achieving",
            descr: '',
            image: iphone,
            steps: [
                '<b>Step 1.</b> We collect information about your experience and goals',
                '<b>Step 2.</b> Aeer creates your personal strategy, based on your answers',
                '<b>Step 3.</b> Find your strategy complete in your account',
            ],
            btnName: 'Continue',
        },
        {
            id: 107,
            progressSmall: 25,
            bar: 'Tasks and Goals',
            pageName: 'main-long-term-gp-goals',
            nextStep: 'main-gp-comments',
            type: 'type20',
            name: "Users complete their goals in <br /> <span>67% of cases,</span> <br /> using their personal goals strategies",
            subtitle: "67% of AEER users achieved their goals <br /> within a year",
            subtitle2: '',
            text3: "AEER helps to achieve long-term results <br /> according to a habit and behavior system, <br /> but not forcing yourself",
            image: quesGraph2,
            btnName: 'Join users',
        },
        {
            id: 108,
            progressSmall: 28,
            barEmpty: true,
            pageName: 'main-gp-comments',
            nextStep: 'main-gp-todo',
            type: 'type7',
            name: "We'll start with small, realistic goals. These small goals will influence your future and form a habit",
            btnName: 'Continue',
            comments: [
                {
                    image: user101,
                    date: '7 Weeks ago',
                    name: 'Ashley Garcia',
                    text: 'Thanks to your program, for the first time in my life, I began to fulfill my tasks! Thank you!'
                },
                {
                    image: user102,
                    date: '6 Weeks ago',
                    name: 'Dylan Nelson',
                    text: 'My productivity has increased using AEER program. Recommend!'
                },
                {
                    image: user103,
                    date: '6 Weeks ago',
                    name: 'Albert Carter',
                    text: 'Now I won’t sleep untill I plan my new day. It has become a habit'
                },
                {
                    image: user104,
                    date: '7 Weeks ago',
                    name: 'Rachel Larson',
                    text: 'Now I recommend this app to everyone at work. Thank you!'
                },
                {
                    image: user105,
                    date: '1 Month ago',
                    name: 'Eric Taylor',
                    text: 'It has become easier to live when the whole plan is visible and I move along it step by step'
                },
            ]
        },
        {
            id: 109,
            progressSmall: 31,
            bar: 'Tasks and Goals',
            pageName: 'main-gp-todo',
            nextStep: 'main-one-gp-comment2',
            type: 'type2',
            name: "Do you create to-do lists?",
            btnName: '',
            answers: [
                'Always do',
                'Tried but gave up',
                'I don’t. Just keep everything in mind'
            ],
        },
        {
            id: 110,
            progressSmall: 35,
            bar: 'Tasks and Goals',
            pageName: 'main-one-gp-comment2',
            nextStep: 'main-how-gp-old',
            type: 'type15',
            image: user4,
            text: "Thanks to AEER, my life had become a series of successful days, full of development and movement forward",
            name: "Martin Robins",
            btnName: 'Boost my productivity',
        },
        {
            id: 111,
            progressSmall: 41,
            bar: 'Follow your transformation',
            pageName: 'main-how-gp-old',
            nextStep: 'main-services-gp-education',
            type: 'type8',
            name: "How old are you?",
            btnName: 'Continue',
        },
        {
            id: 112,
            progressSmall: 47,
            bar: 'Follow your transformation',
            pageName: 'main-services-gp-education',
            nextStep: 'main-clients-gp-say',
            type: 'type2',
            name: "Have you already used any services for <br /> education and personal productivity?",
            btnName: '',
            answers: [
                'Yes, I have used',
                'I have never tried',
            ],
        },
        {
            id: 113,
            progressSmall: 53,
            barNo: 'Follow your transformation',
            pageName: 'main-clients-gp-say',
            nextStep: 'pace-gp-prefer',
            type: 'type7',
            name: "Here’s what our clients have to say",
            btnName: 'Boost my productivity too',
            comments: [
                {
                    image: user106,
                    date: '2 Month ago',
                    name: 'Ivan Harris',
                    text: 'I have already tried lots of different apps and services but I’ve settled down on AEER. It helps me not only to stay productive but also keeps me on track with the mini-courses I need to achieve my goals effectively'
                },
                {
                    image: user107,
                    date: '1 Month ago',
                    name: 'Michael Paul',
                    text: 'This app has replaced me with 5 other ones! Now I stay on the same app and interface during work and study. Everything is convenient in one AEER'
                },
            ],
        },
        {
            id: 114,
            progressSmall: 59,
            barNo: 'Follow your transformation',
            pageName: 'pace-gp-prefer',
            nextStep: 'main-clients-gp-trusted',
            type: 'type2',
            name: "What pace do you prefer to work at?",
            btnName: '',
            answers: [
                'Fast pace',
                'Slow pace',
                'Between the first and the second'
            ],
        },
        {
            id: 115,
            progressSmall: 63,
            barNo: 'Follow your transformation',
            pageName: 'main-clients-gp-trusted',
            nextStep: 'main-gpc-comments',
            type: 'type3',
            name: "Our clients have trusted Aeer <br /> Platform",
            btnName: 'Join users',
            answers: [
                {
                    image: rGraph,
                    name: 'Of users have undergone educational programs'
                },
                {
                    image: rGraph2,
                    name: 'Of users started earning more '
                },
                {
                    image: rGraph3,
                    name: 'Of users reached their goals'
                },
                {
                    image: rGraph4,
                    name: 'Of users have gained new skills'
                }
            ],
        },
        {
            id: 116,
            progressSmall: 68,
            barNo: 'Follow your transformation',
            pageName: 'main-gpc-comments',
            nextStep: 'personal-gp-strategy2',
            type: 'type7',
            name: "We'll start with small, realistic goals. These small goals will influence your future and form a habit",
            btnName: 'Continue',
            comments: [
                {
                    image: user108,
                    date: '2 Month ago',
                    name: 'Louis Baker',
                    text: 'I wish I could find AEER app earlier. It seriously my personal assistant most of the time. All my tasks, goals, notes, funds and even courses are linked in one place and everything is organized.'
                },
                {
                    image: user109,
                    date: '1 Month ago',
                    name: 'Adam Wise',
                    text: 'Indtead of boosting my productivity, my previous productivity apps has so many customization options that they actually ate away my time. Now I can centralize all important tools in one place and run them by few clicks. AEER gives me the feeling of accomplishment and confidence that everything’s organised'
                },
                {
                    image: user110,
                    date: '1 Month ago',
                    name: 'Heather Monroe',
                    text: 'I used to jump between apps and lose track of what to do and where I put things. But this app lets me combine my tasks, notes and my educational content in a single place'
                },
            ]
        },
        {
            id: 117,
            progressSmall: 75,
            barNo: 'Follow your transformation',
            pageName: 'personal-gp-strategy2',
            nextStep: 'free-gp-trial2',
            type: 'type9',
            name: "Just a moment! Creating personal <br /> strategy for you",
            btnName: '',
        },
        {
            id: 118,
            progressSmall: 83,
            barNo: 'Follow your transformation',
            pageName: 'free-gp-trial2',
            nextStep: 'trial-gp-includes2',
            type: 'type10',
            btnName: 'Try it for $1',
        },
        {
            id: 119,
            progressSmall: 90,
            barNo: 'Follow your transformation',
            pageName: 'trial-gp-includes2',
            nextStep: 'payment',
            type: 'type11',
            name: "What 7 days trial does include",
            btnNameV1: 'Change my life',
            comments: [
                {
                    date: '3 Month ago',
                    user: user111,
                    name: 'Diana Ainsley',
                    text: "I got all the necessary tools that provided me with an increase in productivity, financial growth, and soft skills. It's all thanks to AEER"
                },
            ],
        },
        {
            id: 202,
            progressSmall: 7,
            bar: 'Education',
            pageName: 'main-kd-skills',
            nextStep: 'main-tailored-kd-strategy',
            type: 'type2',
            name: "What skills you want to improve?",
            btnName: '',
            answers: [
                'Communication and negotiation',
                'Organization and management',
                'Self-confidence',
                'Work skills',
            ],
        },
        {
            id: 203,
            progressSmall: 10,
            bar: 'Education',
            pageName: 'main-tailored-kd-strategy',
            nextStep: 'main-how-many-kd-courses',
            type: 'type21',
            name: "With a personal-based strategy, our <br /> users have completed more than <br /> <span>3000 courses efficiently.</span> ",
            subtitle: "Learning effectiveness with AEER",
            subtitle2: "Other methods",
            text2: "Aeer system allows you to learn efficiently with <br /> <span>15 minutes sets</span>",
            text: '',
            image: quesGraph4,
            btnName: 'Let’s go',
        },
        {
            id: 204,
            progressSmall: 13,
            bar: 'Education',
            pageName: 'main-how-many-kd-courses',
            nextStep: 'main-course-kd-benefit',
            type: 'type2',
            name: "How many courses have you learned?",
            btnName: '',
            answers: [
                'Less than 5',
                '5-10',
                '10-20',
                'More than 20',
            ],
        },
        {
            id: 205,
            progressSmall: 16,
            bar: 'Education',
            pageName: 'main-course-kd-benefit',
            nextStep: 'main-goals-kd-achive2',
            type: 'type2',
            name: "How many of these courses have <br /> benefited you in practice?",
            btnName: '',
            answers: [
                'Less than 3',
                '3-7',
                '7-10',
                'More than 10',
            ],
        },
        {
            id: 206,
            progressSmall: 19,
            bar: 'Education',
            pageName: 'main-goals-kd-achive2',
            nextStep: 'main-learning-kd-pace',
            type: 'type4',
            name: "With our AEER training system, users <br /> learn <span>2 times</span> more efficiently using <br /> what they have learned in practice",
            image: mQuesGraph3,
            btnName: 'Let’s study smarter',
        },
        {
            id: 207,
            progressSmall: 24,
            bar: 'Education',
            pageName: 'main-learning-kd-pace',
            nextStep: 'main-individual-kd-strategy',
            type: 'type2',
            name: "What is your learning pace?",
            btnName: '',
            answers: [
                'One course per week',
                'One course per month',
                'One course in 3 month',
            ],
        },
        {
            id: 208,
            progressSmall: 27,
            bar: 'A few more steps',
            pageName: 'main-individual-kd-strategy',
            nextStep: 'main-kd-comments',
            type: 'type5',
            name: "Our unique system will <span>create a <br /> personal strategy</span> for your education <br /> and smart goal achieving",
            descr: '',
            image: iphone,
            steps: [
                '<b>Step 1.</b> We collect information about your experience and goals',
                '<b>Step 2.</b> Aeer creates your personal strategy, based on your answers',
                '<b>Step 3.</b> Find your strategy complete in your account',
            ],
            btnName: 'Continue',
        },
        {
            id: 209,
            progressSmall: 31,
            barEmpty: true,
            pageName: 'main-kd-comments',
            nextStep: 'main-kd-device',
            type: 'type7',
            name: "We'll start with small, realistic goals. These small goals will influence your future and form a habit",
            btnName: 'Continue',
            comments: [
                {
                    image: user101,
                    date: '7 Weeks ago',
                    name: 'Ashley Garcia',
                    text: 'Thanks to your program, for the first time in my life, I began to fulfill my tasks! Thank you!'
                },
                {
                    image: user102,
                    date: '6 Weeks ago',
                    name: 'Dylan Nelson',
                    text: 'My productivity has increased using AEER program. Recommend!'
                },
                {
                    image: user103,
                    date: '6 Weeks ago',
                    name: 'Albert Carter',
                    text: 'Now I won’t sleep untill I plan my new day. It has become a habit'
                },
                {
                    image: user104,
                    date: '7 Weeks ago',
                    name: 'Rachel Larson',
                    text: 'Now I recommend this app to everyone at work. Thank you!'
                },
                {
                    image: user105,
                    date: '1 Month ago',
                    name: 'Eric Taylor',
                    text: 'It has become easier to live when the whole plan is visible and I move along it step by step'
                },
            ]
        },
        {
            id: 210,
            progressSmall: 35,
            bar: 'Education',
            pageName: 'main-kd-device',
            nextStep: 'main-one-kd-comment3',
            type: 'type2',
            name: "What device do you usually use for <br /> studying?",
            btnName: '',
            answers: [
                'Phone',
                'Computer',
            ],
        },
        {
            id: 211,
            progressSmall: 42,
            bar: 'Education',
            pageName: 'main-one-kd-comment3',
            nextStep: 'main-services-kd-education',
            type: 'type15',
            image: user4_3,
            text: "AEER offers such diversity of courses, there really is no limit to what I could learn. And the system helps me learn the content efficiently even when I have only 5-10 mins for this",
            name: "Josh Travis",
            btnName: 'Let’s study smarter',
        },
        {
            id: 212,
            progressSmall: 48,
            bar: 'Follow your transformation',
            pageName: 'main-services-kd-education',
            nextStep: 'main-clients-kd-say',
            type: 'type2',
            name: "Have you already used any services for <br /> education and personal productivity?",
            btnName: '',
            answers: [
                'Yes, I have used',
                'I have never tried',
            ],
        },
        {
            id: 213,
            progressSmall: 54,
            barNo: 'Follow your transformation',
            pageName: 'main-clients-kd-say',
            nextStep: 'main-how-kd-old',
            type: 'type7',
            name: "Here’s what our clients have to say",
            btnName: 'Boost my productivity too',
            comments: [
                {
                    image: user106,
                    date: '2 Month ago',
                    name: 'Ivan Harris',
                    text: 'I have already tried lots of different apps and services but I’ve settled down on AEER. It helps me not only to stay productive but also keeps me on track with the mini-courses I need to achieve my goals effectively'
                },
                {
                    image: user107,
                    date: '1 Month ago',
                    name: 'Michael Paul',
                    text: 'This app has replaced me with 5 other ones! Now I stay on the same app and interface during work and study. Everything is convenient in one AEER'
                },
            ],
        },
        {
            id: 214,
            progressSmall: 58,
            bar: 'Follow your transformation',
            pageName: 'main-how-kd-old',
            nextStep: 'main-clients-kd-trusted',
            type: 'type8',
            name: "How old are you?",
            btnName: 'Continue',
        },
        {
            id: 215,
            progressSmall: 63,
            barNo: 'Follow your transformation',
            pageName: 'main-clients-kd-trusted',
            nextStep: 'main-kdc-comments',
            type: 'type3',
            name: "Our clients have trusted Aeer <br /> Platform",
            btnName: 'Join users',
            answers: [
                {
                    image: rGraph,
                    name: 'Of users have undergone educational programs'
                },
                {
                    image: rGraph2,
                    name: 'Of users started earning more '
                },
                {
                    image: rGraph3,
                    name: 'Of users reached their goals'
                },
                {
                    image: rGraph4,
                    name: 'Of users have gained new skills'
                }
            ],
        },
        {
            id: 216,
            progressSmall: 68,
            barNo: 'Follow your transformation',
            pageName: 'main-kdc-comments',
            nextStep: 'personal-kd-strategy2',
            type: 'type7',
            name: "We'll start with small, realistic goals. These small goals will influence your future and form a habit",
            btnName: 'Continue',
            comments: [
                {
                    image: user108,
                    date: '2 Month ago',
                    name: 'Louis Baker',
                    text: 'I wish I could find AEER app earlier. It seriously my personal assistant most of the time. All my tasks, goals, notes, funds and even courses are linked in one place and everything is organized.'
                },
                {
                    image: user109,
                    date: '1 Month ago',
                    name: 'Adam Wise',
                    text: 'Indtead of boosting my productivity, my previous productivity apps has so many customization options that they actually ate away my time. Now I can centralize all important tools in one place and run them by few clicks. AEER gives me the feeling of accomplishment and confidence that everything’s organised'
                },
                {
                    image: user110,
                    date: '1 Month ago',
                    name: 'Heather Monroe',
                    text: 'I used to jump between apps and lose track of what to do and where I put things. But this app lets me combine my tasks, notes and my educational content in a single place'
                },
            ]
        },
        {
            id: 217,
            progressSmall: 76,
            barNo: 'Follow your transformation',
            pageName: 'personal-kd-strategy2',
            nextStep: 'free-kd-trial2',
            type: 'type9',
            name: "Just a moment! Creating personal <br /> strategy for you",
            btnName: '',
        },
        {
            id: 218,
            progressSmall: 84,
            barNo: 'Follow your transformation',
            pageName: 'free-kd-trial2',
            nextStep: 'trial-kd-includes2',
            type: 'type10',
            btnName: 'Try it for $1',
        },
        {
            id: 219,
            progressSmall: 92,
            barNo: 'Follow your transformation',
            pageName: 'trial-kd-includes2',
            nextStep: 'payment',
            type: 'type11',
            name: "What 7 days trial does include",
            btnNameV2: 'Change my life',
            comments: [
                {
                    date: '3 Month ago',
                    user: user111,
                    name: 'Diana Ainsley',
                    text: "I got all the necessary tools that provided me with an increase in productivity, financial growth, and soft skills. It's all thanks to AEER"
                },
            ],
        },
        {
            id: 302,
            progressSmall: 6,
            barEmpty: true,
            pageName: 'main-fin-cr-goal',
            nextStep: 'main-goal-cr-achive',
            type: 'type2',
            name: "What financial goals do you want to achieve?",
            btnName: '',
            answers: [
                'Increase my financial income',
                'Start investing successfully',
                'Increase my savings'
            ],
        },
        {
            id: 303,
            progressSmall: 9,
            bar: 'Finances',
            pageName: 'main-goal-cr-achive',
            nextStep: 'main-level-cr-income',
            type: 'type19',
            name: "We will help you achieve these goals.",
            image: GraphStep,
            text: 'But we will start with small short-term ones. <br /> You’ll see your:',
            btnName: 'Continue',
        },
        {
            id: 304,
            progressSmall: 12,
            bar: 'Finances',
            pageName: 'main-level-cr-income',
            nextStep: 'main-goals-to-cr-achive',
            type: 'type2',
            name: "How would you describe your import parse from level of income?",
            btnName: '',
            answers: [
                'Low',
                'Average',
                'High'
            ],
        },
        {
            id: 305,
            progressSmall: 14,
            bar: 'Finances',
            pageName: 'main-goals-to-cr-achive',
            nextStep: 'main-increase-cr-income',
            type: 'type4',
            name: "On average, our users earn <span>2.3 times</span> <br /> more than before using AEER",
            image: mQuesGraph,
            btnName: 'Increase my income too',
        },
        {
            id: 306,
            progressSmall: 17,
            bar: 'Finances',
            pageName: 'main-increase-cr-income',
            nextStep: 'main-individual-cr-strategy',
            type: 'type2',
            name: "What are your financial worries?",
            btnName: '',
            answers: [
                'Emergency fund',
                'Debt payment',
                'Saving for retirement',
                'Homeownership',
            ],
        },
        {
            id: 307,
            progressSmall: 20,
            bar: 'A few more steps',
            pageName: 'main-individual-cr-strategy',
            nextStep: 'main-long-term-cr-goals',
            type: 'type5',
            name: "Our unique system will <span>create a <br /> personal strategy</span> for your education <br /> and smart goal achieving",
            descr: '',
            image: iphone,
            steps: [
                '<b>Step 1.</b> We collect information about your experience and goals',
                '<b>Step 2.</b> Aeer creates your personal strategy, based on your answers',
                '<b>Step 3.</b> Find your strategy complete in your account',
            ],
            btnName: 'Continue',
        },
        {
            id: 308,
            progressSmall: 23,
            bar: 'Tasks and Goals',
            pageName: 'main-long-term-cr-goals',
            nextStep: 'main-cr-comments',
            type: 'type20',
            name: "Users complete their goals in <br /> <span>62% of cases,</span> <br /> using their personal goals strategies",
            subtitle: "62% of AEER users achieved their goals <br /> within a year",
            subtitle2: '',
            text3: "AEER helps to achieve long-term results <br /> according to a habit and behavior system, <br /> but not forcing yourself",
            image: quesGraph308,
            btnName: 'Join users',
        },
        {
            id: 309,
            progressSmall: 26,
            barEmpty: true,
            pageName: 'main-cr-comments',
            nextStep: 'incomes-cr-expences',
            type: 'type7',
            name: "We'll start with small, realistic goals. These small goals will influence your future and form a habit",
            btnName: 'Continue',
            comments: [
                {
                    image: user101,
                    date: '7 Weeks ago',
                    name: 'Ashley Garcia',
                    text: 'Thanks to your program, for the first time in my life, I began to fulfill my tasks! Thank you!'
                },
                {
                    image: user102,
                    date: '6 Weeks ago',
                    name: 'Dylan Nelson',
                    text: 'My productivity has increased using AEER program. Recommend!'
                },
                {
                    image: user103,
                    date: '6 Weeks ago',
                    name: 'Albert Carter',
                    text: 'Now I won’t sleep untill I plan my new day. It has become a habit'
                },
                {
                    image: user104,
                    date: '7 Weeks ago',
                    name: 'Rachel Larson',
                    text: 'Now I recommend this app to everyone at work. Thank you!'
                },
                {
                    image: user105,
                    date: '1 Month ago',
                    name: 'Eric Taylor',
                    text: 'It has become easier to live when the whole plan is visible and I move along it step by step'
                },
            ]
        },
        {
            id: 310,
            progressSmall: 29,
            bar: 'Finances',
            pageName: 'incomes-cr-expences',
            nextStep: 'main-one-cr-comment',
            type: 'type2',
            name: "Do you track your incomes <br /> and expenses?",
            btnName: '',
            answers: [
                'I do',
                'I don’t',
                'Would like to'
            ],
        },
        {
            id: 311,
            progressSmall: 31,
            bar: 'Finances',
            pageName: 'main-one-cr-comment',
            nextStep: 'main-how-cr-old',
            type: 'type15',
            image: user4_2,
            text: "Thanks to AEER budget tracking became my habit. I’ve been using this for 6 month and now I can clearly see where do I usually overspend. So now I complete my savings goals easier and sooner",
            name: "Emmet Evis",
            btnName: 'Continue',
        },
        {
            id: 312,
            progressSmall: 35,
            bar: 'Follow your transformation',
            pageName: 'main-how-cr-old',
            nextStep: 'main-services-cr-education',
            type: 'type8',
            name: "How old are you?",
            btnName: 'Continue',
        },
        {
            id: 313,
            progressSmall: 43,
            bar: 'Follow your transformation',
            pageName: 'main-services-cr-education',
            nextStep: 'main-clients-cr-say',
            type: 'type2',
            name: "Have you already used any services for <br /> education and personal productivity?",
            btnName: '',
            answers: [
                'Yes, I have used',
                'I have never tried',
            ],
        },
        {
            id: 314,
            progressSmall: 48,
            barNo: 'Follow your transformation',
            pageName: 'main-clients-cr-say',
            nextStep: 'pace-cr-prefer',
            type: 'type7',
            name: "Here’s what our clients have to say",
            btnName: 'Boost my productivity too',
            comments: [
                {
                    image: user106,
                    date: '2 Month ago',
                    name: 'Ivan Harris',
                    text: 'I have already tried lots of different apps and services but I’ve settled down on AEER. It helps me not only to stay productive but also keeps me on track with the mini-courses I need to achieve my goals effectively'
                },
                {
                    image: user107,
                    date: '1 Month ago',
                    name: 'Michael Paul',
                    text: 'This app has replaced me with 5 other ones! Now I stay on the same app and interface during work and study. Everything is convenient in one AEER'
                },
            ],
        },
        {
            id: 315,
            progressSmall: 55,
            barNo: 'Follow your transformation',
            pageName: 'pace-cr-prefer',
            nextStep: 'main-clients-cr-trusted',
            type: 'type2',
            name: "What pace do you prefer to work at?",
            btnName: '',
            answers: [
                'Fast pace',
                'Slow pace',
                'Between the first and the second'
            ],
        },
        {
            id: 316,
            progressSmall: 62,
            barNo: 'Follow your transformation',
            pageName: 'main-clients-cr-trusted',
            nextStep: 'main-crc-comments',
            type: 'type3',
            name: "Our clients have trusted Aeer <br /> Platform",
            btnName: 'Join users',
            answers: [
                {
                    image: rGraph,
                    name: 'Of users have undergone educational programs'
                },
                {
                    image: rGraph2,
                    name: 'Of users started earning more '
                },
                {
                    image: rGraph3,
                    name: 'Of users reached their goals'
                },
                {
                    image: rGraph4,
                    name: 'Of users have gained new skills'
                }
            ],
        },
        {
            id: 317,
            progressSmall: 68,
            barNo: 'Follow your transformation',
            pageName: 'main-crc-comments',
            nextStep: 'personal-cr-strategy2',
            type: 'type7',
            name: "We'll start with small, realistic goals. These small goals will influence your future and form a habit",
            btnName: 'Continue',
            comments: [
                {
                    image: user108,
                    date: '2 Month ago',
                    name: 'Louis Baker',
                    text: 'I wish I could find AEER app earlier. It seriously my personal assistant most of the time. All my tasks, goals, notes, funds and even courses are linked in one place and everything is organized.'
                },
                {
                    image: user109,
                    date: '1 Month ago',
                    name: 'Adam Wise',
                    text: 'Indtead of boosting my productivity, my previous productivity apps has so many customization options that they actually ate away my time. Now I can centralize all important tools in one place and run them by few clicks. AEER gives me the feeling of accomplishment and confidence that everything’s organised'
                },
                {
                    image: user110,
                    date: '1 Month ago',
                    name: 'Heather Monroe',
                    text: 'I used to jump between apps and lose track of what to do and where I put things. But this app lets me combine my tasks, notes and my educational content in a single place'
                },
            ]
        },
        {
            id: 318,
            progressSmall: 76,
            barNo: 'Follow your transformation',
            pageName: 'personal-cr-strategy2',
            nextStep: 'free-cr-trial2',
            type: 'type9',
            name: "Just a moment! Creating personal <br /> strategy for you",
            btnName: '',
        },
        {
            id: 319,
            progressSmall: 84,
            barNo: 'Follow your transformation',
            pageName: 'free-cr-trial2',
            nextStep: 'trial-cr-includes2',
            type: 'type10',
            btnName: 'Try it for $1',
        },
        {
            id: 320,
            progressSmall: 93,
            barNo: 'Follow your transformation',
            pageName: 'trial-cr-includes2',
            nextStep: 'payment',
            type: 'type11',
            name: "What 7 days trial does include",
            btnNameV3: 'Change my life',
            comments: [
                {
                    date: '3 Month ago',
                    user: user111,
                    name: 'Diana Ainsley',
                    text: "I got all the necessary tools that provided me with an increase in productivity, financial growth, and soft skills. It's all thanks to AEER"
                },
            ],
        },
        
        {
            id: 1,
            bar: false,
            pageName: 'main-start1',
            nextStep: 'main-fin-goal',
            name: '<span>Сontrol the chaos</span> in any <br /> areas of your life  ',
            text: 'Succeed in your goals 3 times sooner by <br /> getting personal strategy for:',
            image: mountain,
            type: 'type17',
            btnName: 'Get started',
        },
        // {
        //     id: 2,
        //     progress: false,
        //     bar: false,
        //     progressFull: 40,
        //     pageName: 'control-the-chaos',
        //     nextStep: 'main-fin-goal',
        //     name: 'AEER will help you to <span>control the chaos</span> in any areas of your life step by step  ',
        //     text: 'You will succeed in your goals 3 times sooner by getting unique techniques and educational content by AEER',
        //     image: mGraph1,
        //     type: 'type18',
        //     btnName2: 'Continue',
        // },
        {
            id: 2,
            progress: 5,
            bar: 'Finances',
            pageName: 'main-fin-goal',
            nextStep: 'main-goal-achive',
            type: 'type2',
            name: "What are your financial goals you <br /> want to achieve?",
            btnName: '',
            answers: [
                'Increase my financial income',
                'Start investing successfully',
                'Increase my savings'
            ],
        },
        {
            id: 3,
            progress: 8,
            bar: 'Finances',
            pageName: 'main-goal-achive',
            nextStep: 'main-level-income',
            type: 'type19',
            name: "We will help you achieve these goals.",
            image: GraphStep,
            text: 'But we will start with small short-term ones. <br /> You’ll see your:',
            btnName: 'Continue',
        },
        {
            id: 4,
            progress: 11,
            bar: 'Finances',
            pageName: 'main-level-income',
            nextStep: 'main-increase-income',
            type: 'type2',
            name: "How would you describe your import parse from level of income?",
            btnName: '',
            answers: [
                'Low',
                'Average',
                'High'
            ],
        },
        {
            id: 5,
            progress: 14,
            bar: 'Finances',
            pageName: 'main-increase-income',
            nextStep: 'main-goals-to-achive',
            type: 'type2',
            name: "What are your financial worries?",
            btnName: '',
            answers: [
                'Emergency fund',
                'Debt payment',
                'Saving for retirement',
                'Homeownership',
            ],
        },
        {
            id: 6,
            progress: 17,
            bar: 'Finances',
            pageName: 'main-goals-to-achive',
            nextStep: 'incomes-expences',
            type: 'type4',
            name: "On average, our users earn <span>2.3 times</span> <br /> more than before using AEER",
            image: mQuesGraph,
            btnName: 'Increase my income too',
        },
        {
            id: 7,
            progress: 20,
            bar: 'Finances',
            pageName: 'incomes-expences',
            nextStep: 'main-one-comment',
            type: 'type2',
            name: "Do you track your incomes <br /> and expenses?",
            btnName: '',
            answers: [
                'I do',
                'I don’t',
                'Would like to'
            ],
        },
        {
            id: 8,
            progress: 23,
            bar: 'Finances',
            pageName: 'main-one-comment',
            nextStep: 'smart-goals',
            type: 'type15',
            image: user4_2,
            text: "Thanks to AEER budget tracking became my habit. I’ve been using this for 6 month and now I can clearly see where do I usually overspend. So now I complete my savings goals easier and sooner",
            name: "Emmet Evis",
            btnName: 'Continue',
        },
        {
            id: 9,
            progress: 27,
            barNo: 'Tasks and Goals',
            pageName: 'smart-goals',
            nextStep: 'main-goals',
            name: 'In order to succeed in your financial <br /> goals you need some <br /> <span>skills of smart goals setting</span>',
            text: 'Answer a few questions so that we can create <br /> an individual goal setting strategy for you',
            image: mGraph2,
            type: 'type18',
            btnName: 'Continue',
        },
        {
            id: 10,
            progress: 29,
            bar: 'Tasks and Goals',
            pageName: 'main-goals',
            nextStep: 'main-develop-skills',
            type: 'type2',
            name: "What goals are you focused on?",
            btnName: '',
            answers: [
                'Financial goals',
                'Career goals',
                'Educational goals',
                'Social goals'
            ],
        },
        {
            id: 11,
            progress: 31,
            bar: 'Tasks and Goals',
            pageName: 'main-develop-skills',
            nextStep: 'main-how-many-goals',
            type: 'type6',
            name: "We deliver results!",
            text: "You’ll start with small short term goals <br /> which will give you a sense of <br /> accomplishment and confidence.",
            descr: "These steps will help to <span>shape your future</span> and <br /> <span>form a habit</span>",
            subtitle2: '',
            image: Graph2,
            btnName: 'Сontinue',
        },
        {
            id: 12,
            progress: 33,
            bar: 'Tasks and Goals',
            pageName: 'main-how-many-goals',
            nextStep: 'main-goals-achive',
            type: 'type2',
            name: "How many goals do you accomplish <br /> in a year?",
            btnName: '',
            answers: [
                '0-3',
                '3-5',
                '5-10',
                '10-20'
            ],
        },
        {
            id: 13,
            progress: 35,
            bar: 'Tasks and Goals',
            pageName: 'main-goals-achive',
            nextStep: 'main-achive',
            type: 'type4',
            name: "On average, our users succeed <br /> <span>2.7 times</span> more goals <br /> than before using AEER",
            image: mQuesGraph2,
            btnName: 'Complete my goals too',
        },
        {
            id: 14,
            progress: 37,
            bar: 'Tasks and Goals',
            pageName: 'main-achive',
            nextStep: 'main-long-term-goals',
            type: 'type2',
            name: "How many goals do you want to <br /> achieve in a year?",
            btnName: '',
            answers: [
                '3-5',
                '5-10',
                '10-20',
                '20-30'
            ],
        },
        {
            id: 15,
            progress: 40,
            bar: 'Tasks and Goals',
            pageName: 'main-long-term-goals',
            nextStep: 'main-todo',
            type: 'type20',
            name: "Users complete their goals in <br /> <span>67% of cases,</span> <br /> using their personal goals strategies",
            subtitle: "67% of AEER users achieved their goals <br /> within a year",
            subtitle2: '',
            text3: "AEER helps to achieve long-term results <br /> according to a habit and behavior system, <br /> but not forcing yourself",
            image: quesGraph2,
            btnName: 'Join users',
        },
        {
            id: 16,
            progress: 43,
            bar: 'Tasks and Goals',
            pageName: 'main-todo',
            nextStep: 'main-one-comment2',
            type: 'type2',
            name: "Do you create to-do lists?",
            btnName: '',
            answers: [
                'Always do',
                'Tried but gave up',
                'I don’t. Just keep everything in mind'
            ],
        },
        {
            id: 17,
            progress: 45,
            bar: 'Tasks and Goals',
            pageName: 'main-one-comment2',
            nextStep: 'smart-goals2',
            type: 'type15',
            image: user4,
            text: "Thanks to AEER, my life had become a series of successful days, full of development and movement forward",
            name: "Martin Robins",
            btnName: 'Boost my productivity',
        },
        {
            id: 18,
            progress: 48,
            barNo: 'Tasks and Goals',
            pageName: 'smart-goals2',
            nextStep: 'smart-goals3',
            name: 'In order to achieve financial growth <br /> and goal accomplishment sooner, <br /> you need some <span>techniques and skills.</span>',
            text: 'With those tools you can get skills you need and <br /> succeed in your goals <span>3 times sooner</span>',
            imageBig: mGraph3,
            type: 'type18',
            btnName: 'Continue',
        },
        {
            id: 19,
            progress: 52,
            barNo: 'Education',
            pageName: 'smart-goals3',
            nextStep: 'main-skills',
            name: 'Rapid learning is the <span>master skill</span> now',
            text: 'Please answer a few questions so we can <br /> create your AEER Rapid Learning Strategy <br /> for <span>education</span> and <span>goal achieving</span>',
            image: mGraph4,
            type: 'type18',
            btnName: 'Continue',
        },
        {
            id: 20,
            progress: 54,
            bar: 'Education',
            pageName: 'main-skills',
            nextStep: 'main-tailored-strategy',
            type: 'type2',
            name: "What skills you want to improve?",
            btnName: '',
            answers: [
                'Communication and negotiation',
                'Organization and management',
                'Self-confidence',
                'Work skills',
            ],
        },
        {
            id: 21,
            progress: 57,
            bar: 'Education',
            pageName: 'main-tailored-strategy',
            nextStep: 'main-how-many-courses',
            type: 'type21',
            name: "With a personal-based strategy, our <br /> users have completed more than <br /> <span>3000 courses efficiently.</span> ",
            subtitle: "Learning effectiveness with AEER",
            subtitle2: "Other methods",
            text2: "Aeer system allows you to learn efficiently with <br /> <span>15 minutes sets</span>",
            text: '',
            image: quesGraph4,
            btnName: 'Let’s go',
        },
        {
            id: 22,
            progress: 60,
            bar: 'Education',
            pageName: 'main-how-many-courses',
            nextStep: 'main-course-benefit',
            type: 'type2',
            name: "How many courses have you learned?",
            btnName: '',
            answers: [
                'Less than 5',
                '5-10',
                '10-20',
                'More than 20',
            ],
        },
        {
            id: 23,
            progress: 62,
            bar: 'Education',
            pageName: 'main-course-benefit',
            nextStep: 'main-goals-achive2',
            type: 'type2',
            name: "How many of these courses have <br /> benefited you in practice?",
            btnName: '',
            answers: [
                'Less than 3',
                '3-7',
                '7-10',
                'More than 10',
            ],
        },
        {
            id: 24,
            progress: 65,
            bar: 'Education',
            pageName: 'main-goals-achive2',
            nextStep: 'main-learning-pace',
            type: 'type4',
            name: "With our AEER training system, users <br /> learn <span>2 times</span> more efficiently using <br /> what they have learned in practice",
            image: mQuesGraph3,
            btnName: 'Let’s study smarter',
        },
        {
            id: 25,
            progress: 68,
            bar: 'Education',
            pageName: 'main-learning-pace',
            nextStep: 'main-one-comment3',
            type: 'type2',
            name: "What is your learning pace?",
            btnName: '',
            answers: [
                'One course per week',
                'One course per month',
                'One course in 3 month',
            ],
        },
        {
            id: 26,
            progress: 71,
            bar: 'Education',
            pageName: 'main-one-comment3',
            nextStep: 'main-device',
            type: 'type15',
            image: user4_3,
            text: "AEER offers such diversity of courses, there really is no limit to what I could learn. And the system helps me learn the content efficiently even when I have only 5-10 mins for this",
            name: "Josh Travis",
            btnName: 'Let’s study smarter',
        },
        {
            id: 27,
            progress: 73,
            bar: 'Education',
            pageName: 'main-device',
            nextStep: 'main-comments',
            type: 'type2',
            name: "What device do you usually use for <br /> studying?",
            btnName: '',
            answers: [
                'Phone',
                'Computer',
            ],
        },
        {
            id: 28,
            progress: 77,
            barNo: 'Follow your transformation',
            pageName: 'main-comments',
            nextStep: 'main-services-education',
            type: 'type7',
            name: "We'll start with small, realistic goals. These small goals will influence your future and form a habit",
            btnName: 'Continue',
            comments: [
                {
                    image: user7,
                    date: '2 Month ago',
                    name: 'Riko Reis',
                    text: 'I wish I could find AEER app earlier. It seriously my personal assistant most of the time. All my tasks, goals, notes, funds and even courses are linked in one place and everything is organized.'
                },
                {
                    image: user8,
                    date: '1 Month ago',
                    name: 'Adam Wise',
                    text: 'Indtead of boosting my productivity, my previous productivity apps has so many customization options that they actually ate away my time. Now I can centralize all important tools in one place and run them by few clicks. AEER gives me the feeling of accomplishment and confidence that everything’s organised'
                },
                {
                    image: user9,
                    date: '1 Month ago',
                    name: 'Sandra Spancor',
                    text: 'I used to jump between apps and lose track of what to do and where I put things. But this app lets me combine my tasks, notes and my educational content in a single place'
                },
            ]
        },
        {
            id: 29,
            progress: 79,
            bar: 'Follow your transformation',
            pageName: 'main-services-education',
            nextStep: 'main-clients-say',
            type: 'type2',
            name: "Have you already used any services for <br /> education and personal productivity?",
            btnName: '',
            answers: [
                'Yes, I have used',
                'I have never tried',
            ],
        },
        {
            id: 30,
            progress: 81,
            barNo: 'Follow your transformation',
            pageName: 'main-clients-say',
            nextStep: 'main-how-old',
            type: 'type7',
            name: "Here’s what our clients have to say",
            btnName: 'Boost my productivity too',
            comments: [
                {
                    image: user5,
                    date: '2 Month ago',
                    name: 'Ivan Dice',
                    text: 'I have already tried lots of different apps and services but I’ve settled down on AEER. It helps me not only to stay productive but also keeps me on track with the mini-courses I need to achieve my goals effectively'
                },
                {
                    image: user6,
                    date: '1 Month ago',
                    name: 'Michael Lappa',
                    text: 'This app has replaced me with 5 other ones! Now I stay on the same app and interface during work and study. Everything is convenient in one AEER'
                },
            ],
        },
        {
            id: 31,
            progress: 83,
            bar: 'Follow your transformation',
            pageName: 'main-how-old',
            nextStep: 'main-clients-trusted',
            type: 'type8',
            name: "How old are you?",
            btnName: 'Continue',
        },
        {
            id: 32,
            progress: 85,
            barNo: 'Follow your transformation',
            pageName: 'main-clients-trusted',
            nextStep: 'main-individual-strategy',
            type: 'type3',
            name: "Our clients have trusted Aeer <br /> Platform",
            btnName: 'Join users',
            answers: [
                {
                    image: rGraph,
                    name: 'Of users have undergone educational programs'
                },
                {
                    image: rGraph2,
                    name: 'Of users started earning more '
                },
                {
                    image: rGraph3,
                    name: 'Of users reached their goals'
                },
                {
                    image: rGraph4,
                    name: 'Of users have gained new skills'
                }
            ],
        },
        {
            id: 33,
            progress: 87,
            bar: 'A few more steps',
            pageName: 'main-individual-strategy',
            nextStep: 'personal-strategy2',
            type: 'type5',
            name: "Our unique system will <span>create a <br /> personal strategy</span> for your education <br /> and smart goal achieving",
            descr: '',
            image: iphone,
            steps: [
                '<b>Step 1.</b> We collect information about your experience and goals',
                '<b>Step 2.</b> Aeer creates your personal strategy, based on your answers',
                '<b>Step 3.</b> Find your strategy complete in your account',
            ],
            btnName: 'Continue',
        },
        // {
        //     id: 35,
        //     progress: 35,
        //     progressFull: 40,
        //     nextStep: 'personal-strategy2',
        //     pageName: 'comments2',
        //     type: 'type7',
        //     name: "We'll start with small, realistic goals. These small goals will influence your future and form a habit",
        //     btnName: 'Create a personal strategy',
        //     comments: [
        //         {
        //             image: user7,
        //             date: '2 Month ago',
        //             name: 'Riko Reis',
        //             text: 'I wish I could find AEER app earlier. It seriously my personal assistant most of the time. All my tasks, goals, notes, funds and even courses are linked in one place and everything is organized.'
        //         },
        //         {
        //             image: user8,
        //             date: '1 Month ago',
        //             name: 'Adam Wise',
        //             text: 'Indtead of boosting my productivity, my previous productivity apps has so many customization options that they actually ate away my time. Now I can centralize all important tools in one place and run them by few clicks. AEER gives me the feeling of accomplishment and confidence that everything’s organised'
        //         },
        //         {
        //             image: user9,
        //             date: '1 Month ago',
        //             name: 'Sandra Spancor',
        //             text: 'I used to jump between apps and lose track of what to do and where I put things. But this app lets me combine my tasks, notes and my educational content in a single place'
        //         },
        //     ]
        // },
        {
            id: 34,
            progress: 89,
            barNo: 'Follow your transformation',
            pageName: 'personal-strategy2',
            nextStep: 'free-trial2',
            type: 'type9',
            name: "Just a moment! Creating personal <br /> strategy for you",
            btnName: '',
        },
        {
            id: 35,
            progress: 91,
            barNo: 'Follow your transformation',
            pageName: 'free-trial2',
            nextStep: 'trial-includes2',
            type: 'type10',
            btnName: 'Try it for $1',
        },
        {
            id: 36,
            progress: 93,
            barNo: 'Follow your transformation',
            pageName: 'trial-includes2',
            nextStep: 'payment',
            type: 'type11',
            name: "What 7 days trial does include",
            btnName: 'Change my life',
            comments: [
                {
                    date: '3 Month ago',
                    user: user10,
                    name: 'Jade Miller',
                    text: "I got all the necessary tools that provided me with an increase in productivity, financial growth, and soft skills. It's all thanks to AEER"
                },
            ],
        },
        {
            id: 37,
            progress: 95,
            barNo: 'A few more steps',
            pageName: 'sign-up2',
            nextStep: 'payment2',
            type: 'type12',
            btnName: 'Get a 7-day free trial',
        },
        // {
        //     id: 38,
        //     progress: 96,
        //     barNo: 'A few more steps',
        //     pageName: 'add-payment2',
        //     nextStep: 'payment2',
        //     type: 'type16',
        //     name: "Add payment over $1",
        //     text: "Money shouldn't stay in the way of reaching your goals. So choose an amount than you think is reasonable to try us out",
        //     descr: "it costs us 7,49$ to cover our expenses for the trial, but please choose an amount you’re comfortable with",
        //     btnName: 'Confirm',
        // },
        {
            id: 39,
            progress: 96,
            barNo: 'Finish setting up your account',
            pageName: 'payment2',
            nextStep: 'finish2',
            type: 'type13',
            btnName: 'Pay $2.49 USD',
        },
        {
            id: 40,
            progress: 100,
            barNo: 'Finish setting up your account',
            pageName: 'finish2',
            nextStep: '',
            type: 'type14',
            btnName: '',
        },
    ]);

    const [eventView, setEventView] = useState(false);
    const [eventCart, setEventCart] = useState(false);

    const changePrice = (price) => {
        setTrialPrice(price);
    }

    const validateCheckbox = (number) => {
        console.log('Checked checkbox');
    }
    const nextSteption = (link) => {
        setActiveQues(activeQues + 1);

        setTimeout(() => {
            navigate(link);
        }, 300);
    }

    const validateRadio = () => {
        // setBtnStatus(true);
        console.log('Checked radio');
    }

    const calculateWidth = () => {
        questions.map( (key) => {
            if(id === key.pageName){
                // Big progress calculate
                const progressVal = key.progress;
                setProgressWidth(progressVal);
                if(progressVal > 26 && progressVal < 50){
                    setProgressStatus('first');
                } else if(progressVal > 50 && progressVal < 75){
                    setProgressStatus('second');
                } else if(progressVal > 75 && progressVal < 100){
                    setProgressStatus('third');
                } else if(progressVal > 99){
                    setProgressStatus('forth');
                } else{
                    setProgressStatus('');
                }

                // Small progress calculate
                const progressValSmall = key.progressSmall;
                setProgressWidthSmall(progressValSmall);
                if(progressValSmall > 33 && progressValSmall < 66){
                    setProgressStatusSmall('firstSmall');
                } else if(progressValSmall > 66 && progressValSmall < 99){
                    setProgressStatusSmall('secondSmall');
                } else if(progressValSmall > 99){
                    setProgressStatusSmall('thirdSmall');
                } else{
                    setProgressStatusSmall('');
                }
                return key;
            }
        });
    }

    useEffect( () => {
        calculateWidth();
    });

    useEffect( () => {
        if(!eventView && id === 'main-fin-goal'){
            // fbq("track", "ViewContent");
            setEventView(true);
        }
        if( (!eventView && id === 'main-gp-goals') || (!eventView && id === 'main-fin-cr-goal') || (!eventView && id === 'main-kd-skills') ){
            // fbq("track", "ViewContent");
            console.log('fb track ViewContent');
            setEventView(true);
        }

        if(!eventCart && id === 'trial-includes2'){
            // fbq("track", "AddToCart");
            setEventCart(true);
        }
        if( (!eventCart && id === 'trial-gp-includes2') || (!eventCart && id === 'trial-cr-includes2') || (!eventCart && id === 'trial-kd-includes2') ){
            // fbq("track", "AddToCart");
            console.log('fb track AddToCart');
            setEventCart(true);
        }
    }, [id]);

    return (
        <>
            <style>
                {
                    `body {
                        background: #F8F8FD;
                        min-height: initial
                    }
                    body, html{
                        height: 100%;
                    }`
                }
            </style>
            <div className="questionSection">
                <div className="questionWrap">

                    {
                        questions.map( (quesKey, index) => {
                            if(quesKey.pageName == id){
                                return (
                                    <div key={index}>
                                        {
                                            (quesKey.bar) && (
                                                <div className="questionHeading">
                                                    <a
                                                        className="questionBack"
                                                        onClick={() => navigate(-1)}>
                                                        <img src={ArrowPrev} alt="" />
                                                    </a>
                                                    <p>{quesKey.bar}</p>
                                                </div>
                                            )
                                        }
                                        {
                                            (quesKey.barNo) && (
                                                <div className="questionHeading">
                                                    <p>{quesKey.barNo}</p>
                                                </div>
                                            )
                                        }
                                        {
                                            (quesKey.barEmpty) && (
                                                <div className="questionHeading">
                                                    <p style={{
                                                        opacity: 0,
                                                        pointerEvents: 'none'
                                                    }}>Heading</p>
                                                </div>
                                            )
                                        }
                                        {
                                            (quesKey.progress) && (
                                                <div className="questionProgress">
                                                    <div className={`questionProgressLine ${progressStatus}`}>
                                                        <span>
                                                            <img src={DotBar} alt="" />
                                                        </span>
                                                        <span>
                                                            <img src={DotBar} alt="" />
                                                        </span>
                                                        <span>
                                                            <img src={DotBar} alt="" />
                                                        </span>
                                                        <span>
                                                            <img src={DotBar} alt="" />
                                                        </span>
                                                        <div style={{width: progressWidth+'%'}}></div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {
                                            (quesKey.progressSmall) && (
                                                <div className="questionProgress">
                                                    <div className={`questionProgressLine small ${progressStatusSmall}`}>
                                                        <span>
                                                            <img src={DotBar} alt="" />
                                                        </span>
                                                        <span>
                                                            <img src={DotBar} alt="" />
                                                        </span>
                                                        <span>
                                                            <img src={DotBar} alt="" />
                                                        </span>
                                                        <div style={{width: progressWidthSmall+'%'}}></div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                )
                            }
                        })
                    }

                    {
                        questions.map( (quesKey, index) => {
                            if(quesKey.pageName == id){
                                switch(quesKey.type){
                                    case 'type1':
                                        return (
                                            <div key={index}>
                                                <QType1
                                                    quesKey={quesKey}
                                                    index={index}
                                                    validateRadio={validateRadio}
                                                    nextSteption={nextSteption} />
                                                {
                                                    (quesKey.btnName !== '') && (
                                                        <Link className="questionBtn" to={`/question/${quesKey.nextStep}`}>
                                                            <span>{quesKey.btnName}</span>
                                                            <img src={ArrowNext} alt="" />
                                                        </Link>
                                                    )
                                                }
                                            </div>
                                        )
                                    break;
                                    case 'type2':
                                        return (
                                            <div key={index}>
                                                <QType2
                                                    quesKey={quesKey}
                                                    index={index}
                                                    validateRadio={validateRadio}
                                                    nextSteption={nextSteption} />
                                                {
                                                    (quesKey.btnName !== '') && (
                                                        <Link className="questionBtn" to={`/question/${quesKey.nextStep}`}>
                                                            <span>{quesKey.btnName}</span>
                                                            <img src={ArrowNext} alt="" />
                                                        </Link>
                                                    )
                                                }
                                                <p className="questionSecure">Your information is 100% secure. AEER Platform does not share your personal information with third parties</p>
                                            </div>
                                        )
                                    break;
                                    case 'type3':
                                        return (
                                            <div key={index}>
                                                <QType3
                                                    quesKey={quesKey}
                                                    index={index} />
                                                {
                                                    (quesKey.btnName !== '') && (
                                                        <Link className="questionBtn" to={`/question/${quesKey.nextStep}`}>
                                                            <span>{quesKey.btnName}</span>
                                                            <img src={ArrowNext} alt="" />
                                                        </Link>
                                                    )
                                                }
                                            </div>
                                        )
                                    break;
                                    case 'type4':
                                        return (
                                            <div key={index}>
                                                <QType4
                                                    quesKey={quesKey} />
                                                {
                                                    (quesKey.btnName !== '') && (
                                                        <Link className="questionBtn" to={`/question/${quesKey.nextStep}`}>
                                                            <span>{quesKey.btnName}</span>
                                                            <img src={ArrowNext} alt="" />
                                                        </Link>
                                                    )
                                                }
                                            </div>
                                        )
                                    break;
                                    case 'type5':
                                        return (
                                            <div key={index}>
                                                <QType5
                                                    quesKey={quesKey} />
                                                {
                                                    (quesKey.btnName !== '') && (
                                                        <Link className="questionBtn" to={`/question/${quesKey.nextStep}`}>
                                                            <span>{quesKey.btnName}</span>
                                                            <img src={ArrowNext} alt="" />
                                                        </Link>
                                                    )
                                                }
                                            </div>
                                        )
                                    break;
                                    case 'type6':
                                        return (
                                            <div key={index}>
                                                <QType6
                                                    quesKey={quesKey} />
                                                {
                                                    (quesKey.btnName !== '') && (
                                                        <Link className="questionBtn" to={`/question/${quesKey.nextStep}`}>
                                                            <span>{quesKey.btnName}</span>
                                                            <img src={ArrowNext} alt="" />
                                                        </Link>
                                                    )
                                                }
                                            </div>
                                        )
                                    break;
                                    case 'type7':
                                        return (
                                            <div key={index}>
                                                <QType7
                                                    quesKey={quesKey} />
                                                {
                                                    (quesKey.btnName !== '') && (
                                                        <Link className="questionBtn" to={`/question/${quesKey.nextStep}`}>
                                                            <span>{quesKey.btnName}</span>
                                                            <img src={ArrowNext} alt="" />
                                                        </Link>
                                                    )
                                                }
                                            </div>
                                        )
                                    break;
                                    case 'type8':
                                        return (
                                            <div key={index}>
                                                <QType8
                                                    quesKey={quesKey} />
                                                {
                                                    (quesKey.btnName !== '') && (
                                                        <div className="questionBtnCont">
                                                            <Link className="questionSkip" to={`/question/${quesKey.nextStep}`}>
                                                                <span>Skip</span>
                                                            </Link>
                                                            <Link className="questionBtn static" to={`/question/${quesKey.nextStep}`}>
                                                                <span>{quesKey.btnName}</span>
                                                                <img src={ArrowNext} alt="" />
                                                            </Link>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    break;
                                    case 'type9':
                                        return (
                                            <div key={index}>
                                                <QType9
                                                    quesKey={quesKey} />
                                                {
                                                    (quesKey.btnName !== '') && (
                                                        <Link className="questionBtn" to={`/question/${quesKey.nextStep}`}>
                                                            <span>{quesKey.btnName}</span>
                                                            <img src={ArrowNext} alt="" />
                                                        </Link>
                                                    )
                                                }
                                            </div>
                                        )
                                    break;
                                    case 'type10':
                                        return (
                                            <div key={index}>
                                                <QType10
                                                    quesKey={quesKey} />
                                                {
                                                    (quesKey.btnName !== '') && (
                                                        <div className="questionBtnTrial">
                                                            <p>After chargeless 7-day trial, the subscription cost is just $4.99 per month. Cancel your subscription at any time from personal account</p>
                                                            <Link className="questionBtn static" to={`/question/${quesKey.nextStep}`}>
                                                                <span>{quesKey.btnName}</span>
                                                                <img src={ArrowNext} alt="" />
                                                            </Link>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    break;
                                    case 'type11':
                                        return (
                                            <div key={index}>
                                                <QType11
                                                    quesKey={quesKey} />
                                                {
                                                    (quesKey.btnName) && (
                                                        <Link id="selectQuestionBtn" className="questionBtn" to={`/${quesKey.nextStep}`}>
                                                            <span id="selectQuestionBtnSpan">{quesKey.btnName}</span>
                                                            <img id="selectQuestionBtnIcon" src={ArrowNext} alt="" />
                                                        </Link>
                                                    )
                                                }
                                                {
                                                    (quesKey.btnNameV1) && (
                                                        <Link id="selectQuestion-1-Btn" className="questionBtn" to={`/${quesKey.nextStep}`}>
                                                            <span id="selectQuestionBtn-1-Span">{quesKey.btnNameV1}</span>
                                                            <img id="selectQuestionBtn-1-Icon" src={ArrowNext} alt="" />
                                                        </Link>
                                                    )
                                                }
                                                {
                                                    (quesKey.btnNameV2) && (
                                                        <Link id="selectQuestion-2-Btn" className="questionBtn" to={`/${quesKey.nextStep}`}>
                                                            <span id="selectQuestionBtn-2-Span">{quesKey.btnNameV2}</span>
                                                            <img id="selectQuestionBtn-2-Icon" src={ArrowNext} alt="" />
                                                        </Link>
                                                    )
                                                }
                                                {
                                                    (quesKey.btnNameV3) && (
                                                        <Link id="selectQuestion-3-Btn" className="questionBtn" to={`/${quesKey.nextStep}`}>
                                                            <span id="selectQuestionBtn-3-Span">{quesKey.btnNameV3}</span>
                                                            <img id="selectQuestionBtn-3-Icon" src={ArrowNext} alt="" />
                                                        </Link>
                                                    )
                                                }
                                            </div>
                                        )
                                    break;
                                    case 'type12':
                                        return (
                                            <div key={index}>
                                                <QType12
                                                    quesKey={quesKey} />
                                                {/* {
                                                    (quesKey.btnName !== '') && (
                                                        <Link className="questionBtn" to={`/question/${quesKey.nextStep}`}>
                                                            <span>{quesKey.btnName}</span>
                                                            <img src={ArrowNext} alt="" />
                                                        </Link>
                                                    )
                                                } */}
                                            </div>
                                        )
                                    break;
                                    case 'type13':
                                        return (
                                            <div key={index}>
                                                <QType13
                                                    quesKey={quesKey}
                                                    price={trialPrice} />
                                                {/* {
                                                    (quesKey.btnName !== '') && (
                                                        <Link className="questionBtn" to={`/question/${quesKey.nextStep}`}>
                                                            <span>Pay ${trialPrice} USD</span>
                                                        </Link>
                                                    )
                                                } */}
                                            </div>
                                        )
                                    break;
                                    case 'type14':
                                        return (
                                            <div key={index}>
                                                <QType14
                                                    quesKey={quesKey} />
                                                {
                                                    (quesKey.btnName !== '') && (
                                                        <Link className="questionBtn" to={`/question/${quesKey.nextStep}`}>
                                                            <span>{quesKey.btnName}</span>
                                                            <img src={ArrowNext} alt="" />
                                                        </Link>
                                                    )
                                                }
                                            </div>
                                        )
                                    break;
                                    case 'type15':
                                        return (
                                            <div key={index}>
                                                <QType15
                                                    quesKey={quesKey} />
                                                {
                                                    (quesKey.btnName !== '') && (
                                                        <Link className="questionBtn" to={`/question/${quesKey.nextStep}`}>
                                                            <span>{quesKey.btnName}</span>
                                                            <img src={ArrowNext} alt="" />
                                                        </Link>
                                                    )
                                                }
                                            </div>
                                        )
                                    break;
                                    case 'type16':
                                        return (
                                            <div key={index}>
                                                <QType16
                                                    quesKey={quesKey}
                                                    changePrice={changePrice} />
                                                {
                                                    (quesKey.btnName !== '') && (
                                                        <Link className="questionBtn" to={`/question/${quesKey.nextStep}`}>
                                                            <span>{quesKey.btnName}</span>
                                                            <img src={ArrowNext} alt="" />
                                                        </Link>
                                                    )
                                                }
                                            </div>
                                        )
                                    break;
                                    case 'type17':
                                        return (
                                            <div key={index}>
                                                <QType17
                                                    quesKey={quesKey} />
                                                {
                                                    (quesKey.btnName !== '') && (
                                                        <Link className="questionBtn questionBtn2" to={`/question/${quesKey.nextStep}`}>
                                                            <span>{quesKey.btnName}</span>
                                                            <img src={ArrowNext} alt="" />
                                                        </Link>
                                                    )
                                                }
                                            </div>
                                        )
                                    break;
                                    case 'type18':
                                        return (
                                            <div key={index}>
                                                <QType18
                                                    quesKey={quesKey} />
                                                {
                                                    (quesKey.btnName) && (
                                                        <Link className="questionBtn" to={`/question/${quesKey.nextStep}`}>
                                                            <span>{quesKey.btnName}</span>
                                                            <img src={ArrowNext} alt="" />
                                                        </Link>
                                                    )
                                                }
                                                {
                                                    (quesKey.btnName2) && (
                                                        <Link className="questionBtn questionBtn2" to={`/question/${quesKey.nextStep}`}>
                                                            <span>{quesKey.btnName2}</span>
                                                            <img src={ArrowNext} alt="" />
                                                        </Link>
                                                    )
                                                }
                                            </div>
                                        )
                                    break;
                                    case 'type19':
                                        return (
                                            <div key={index}>
                                                <QType19
                                                    quesKey={quesKey} />
                                                {
                                                    (quesKey.btnName !== '') && (
                                                        <Link className="questionBtn" to={`/question/${quesKey.nextStep}`}>
                                                            <span>{quesKey.btnName}</span>
                                                            <img src={ArrowNext} alt="" />
                                                        </Link>
                                                    )
                                                }
                                            </div>
                                        )
                                    break;
                                    case 'type20':
                                        return (
                                            <div key={index}>
                                                <QType20
                                                    quesKey={quesKey} />
                                                {
                                                    (quesKey.btnName !== '') && (
                                                        <Link className="questionBtn" to={`/question/${quesKey.nextStep}`}>
                                                            <span>{quesKey.btnName}</span>
                                                            <img src={ArrowNext} alt="" />
                                                        </Link>
                                                    )
                                                }
                                            </div>
                                        )
                                    break;
                                    case 'type21':
                                        return (
                                            <div key={index}>
                                                <QType21
                                                    quesKey={quesKey} />
                                                {
                                                    (quesKey.btnName !== '') && (
                                                        <Link className="questionBtn" to={`/question/${quesKey.nextStep}`}>
                                                            <span>{quesKey.btnName}</span>
                                                            <img src={ArrowNext} alt="" />
                                                        </Link>
                                                    )
                                                }
                                            </div>
                                        )
                                    break;
                                }
                            }

                        })
                    }
                </div>
            </div>

        </>
    )
}

export default Question;

export const Head: HeadFC = () => (
    <>
        <title>Quiz - AEER PLATFORM</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
    </>
);

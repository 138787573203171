// import '../../styles/pages/question.scss';
import React, {memo, useState} from 'react';
import parse from 'html-react-parser';

import {
    LoadingGif
} from "../../images";

const QType2 = ({quesKey, index, nextSteption, validateRadio}) => {

    const [isLoading, setLoading] = useState(true);

    setTimeout(function(){
        setLoading(false)
    }, 0)

    // if(isLoading){
    //     return (
    //         <div className="loadWrap loadWrapQuiz loadWrapQuestion">
    //             <div className="loadWrapLogo">
    //                 <div className="loadWrapText">
    //                     <img width={16} src={LoadingGif} alt=""/>
    //                     {/* Loading.. */}
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }

    return (
        <>
            <div className='question active' >
                <p className={`questionName wow delay ${isLoading ? '' : 'animate'}`}>{parse(quesKey.name)}</p>
                <div className="questionBox">
                    {
                        quesKey.answers.map( (answKey, index2) => (
                            <div 
                                className={`questionRadio wow delay${index2}1 ${isLoading ? '' : 'animate'}`} 
                                key={index2}
                                onClick={() => validateRadio()}>
                                <input type="radio" id={`answer-${index}-${index2}`} name={`ques-${index}`} value={answKey} />
                                <label 
                                    htmlFor={`answer-${index}-${index2}`}
                                    onClick={() => nextSteption('/question/'+quesKey.nextStep)}>
                                    <span>{answKey}</span>
                                </label>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>     
    )
}

export default memo(QType2);

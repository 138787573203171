// import '../../styles/pages/question.scss';
import React, {memo, useState} from 'react';
import { Link } from 'gatsby';

import {
    LoadingGif,
    starComment
} from "../../images";

const QType11 = ({quesKey}) => {

    const [isLoading, setLoading] = useState(true);

    setTimeout(function(){
        setLoading(false)
    }, 300)

    // if(isLoading){
    //     return (
    //         <div className="loadWrap loadWrapQuiz loadWrapQuestion">
    //             <div className="loadWrapLogo">
    //                 <div className="loadWrapText">
    //                     <img width={16} src={LoadingGif} alt=""/>
    //                     {/* Loading.. */}
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }

    const [imageStatus, setImageStatus] = useState(true);

    if(imageStatus){
        return <img src={starComment} onLoad={()=> setImageStatus(false)} style={{display: 'none'}} />
    }

    return (
        <>
            <div className='question active' >
                <p className={`questionName medium wow delay ${isLoading ? '' : 'animate'}`}>
                    {quesKey.name}
                </p>
                <div className={`questionList wow delay01 ${isLoading ? '' : 'animate'}`}>
                    <ul>
                        <li>Personal strategy</li>
                        <li>Personal goals management: set goals, and achieve them</li>
                        <li>Personal financial management in a simple interface</li>
                        <li>Ideas and solutions recording to track personal success stories</li>
                    </ul>
                </div>
                {
                    quesKey.comments.map( (key, index) => (
                        <div key={index} className={`questionComment wow delay11 ${isLoading ? '' : 'animate'}`}>
                            <div className="questionCommentBox">
                                <div className="questionCommentLeft">
                                    <div className="questionCommentUser">
                                        <img src={key.user} alt="" />
                                    </div>
                                    <div className="questionCommentInfo">
                                        <span>{key.name}</span>
                                        <div className="questionStars">
                                            <img src={starComment} alt="" />
                                            <img src={starComment} alt="" />
                                            <img src={starComment} alt="" />
                                            <img src={starComment} alt="" />
                                            <img src={starComment} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <p className="questionCommentDate">
                                    {key.date}
                                </p>
                            </div>
                            <div className="questionCommentText">{key.text}</div>
                        </div>
                    ))
                }
                <div className="questionFooter static">
                    <p>© AEER PLATFORM INC <br /> 8 THE GREEN STE A DOVER, DE 19901</p>
                    <Link to="/terms">Terms of User</Link>
                    <Link to="/privacy">Privacy policy</Link>
                    <Link to="/cancel">Unsubscribe</Link>
                    {/* <Link to="/money-back">Money-Back Policy</Link>
                    <Link to="/cancel">Сancel subscription</Link>
                    <Link to="/terms">Terms of User</Link>
                    <Link to="/privacy">Privacy policy</Link>
                    <Link to="/cookie">Cookie policy</Link> */}
                </div>
            </div>
        </>     
    )
}

export default memo(QType11);

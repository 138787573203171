// import '../../styles/pages/question.scss';
import React, {memo, useState} from 'react';
import { Link } from 'gatsby';

import {
    LoadingGif,
    iphoneFinish,
    finishAppStore,
    finishGPlay,
    iphoneFinish2
} from "../../images";

const QType14 = () => {

    // const [isLoading, setLoading] = useState(true);

    // setTimeout(function(){
    //     setLoading(false)
    // }, 300)

    // if(isLoading){
    //     return (
    //         <div className="loadWrap loadWrapQuiz loadWrapQuestion">
    //             <div className="loadWrapLogo">
    //                 <div className="loadWrapText">
    //                     <img width={16} src={LoadingGif} alt=""/>
    //                     {/* Loading.. */}
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }

    const [imageStatus, setImageStatus] = useState(true);

    if(imageStatus){
        return (
            <div>
                <img src={iphoneFinish2} style={{display: 'none'}} />
                <img src={finishAppStore} style={{display: 'none'}} />
                <img src={finishGPlay} onLoad={()=> setImageStatus(false)} style={{display: 'none'}} />
            </div>
        )
    }

    return (
        <>
            <div className='question active withFooter' >
                <p className="questionName big2 mb0">Start transforming your life with AEER Platform</p>
                <div className="quesFinish">
                    {/* <img src={iphoneFinish} alt="" className="quesFinishPhone" /> */}
                    <img src={iphoneFinish2} alt="" className="quesFinishPhone2" />
                    <div className="quesRemark">
                        <p>Your free trial starts today and ends on 26 Nov 2022.</p>
                    </div>
                    <div className="quesFinishDownload">
                        <a href="">
                            <img src={finishAppStore} alt="" />
                        </a>
                        <a href="">
                            <img src={finishGPlay} alt="" />
                        </a>
                    </div>
                    <p>Your subscription will auto-renew every month after that. You can cancel it at any time up to 24 hours before the next renewal</p>
                    <p>After chargeless 7-day trial, the subscription cost is just $4.99 per month. Cancel your subscription at any time from personal account</p>
                </div>
                <div className="questionFooter">
                    <p>© AEER PLATFORM INC <br /> 8 THE GREEN STE A DOVER, DE 19901</p>
                    {/* <Link to="/money-back">Money-Back Policy</Link> */}
                    {/* <Link to="/cancel">Сancel subscription</Link> */}
                    <Link to="/terms">Terms of User</Link>
                    <Link to="/privacy">Privacy policy</Link>
                    <Link to="/cancel">Unsubscribe</Link>
                </div>
            </div>
        </>     
    )
}

export default memo(QType14);

import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";

let interval = undefined;

type Props = {
  size: number,
  strokeWidth: number,
  nextStep: string,
  color: string,
}

const CircularProgress = ({ size, strokeWidth, nextStep, color }: Props) => {
    const [running, setRunning] = useState(true);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (running) {
            interval = setInterval(() => {
                setProgress((prev) => prev + 1);
            }, 100);
        } else {
            clearInterval(interval);
        }
    }, [running]);
    
    useEffect(() => {
        // if (progress === 39) {
        //     setRunning(false);
        //     clearInterval(interval);
        
        //     setRunning(true);
        //     interval = setInterval(() => {
        //         setProgress((prev) => prev + 1);
        //     }, 200);
        // } else if(progress === 56){
        //     setRunning(false);
        //     clearInterval(interval);
        
        //     setRunning(true);
        //     interval = setInterval(() => {
        //         setProgress((prev) => prev + 1);
        //     }, 50);
        // } else if(progress === percentage){
        //     clearInterval(interval);
        // }
        if (progress === 100) {
            clearInterval(interval);
            setTimeout(function(){
                navigate('/question/'+nextStep);
            }, 300);
        } 
    }, [progress]);

  const viewBox = `0 0 ${size} ${size}`;
  const radius = (size - strokeWidth) / 2;
  const circumference = radius * Math.PI * 2;
  const dash = (progress * circumference) / 100;

  return (
    <svg width={size} height={size} viewBox={viewBox}>
      <circle
        fill="none"
        stroke="#EBEBEB"
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
      />
      <circle
        fill="none"
        stroke={color}
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
        transform={`rotate(-90 ${size / 2} ${size / 2})`}
        strokeDasharray={[dash, circumference - dash]}
        strokeLinecap="round"
        style={{ transition: "all 0.5s" }}
      />
      <text
        fill="black"
        fontSize="18px"
        x="50%"
        y="50%"
        dy="8px"
        textAnchor="middle"
      >
        {`${progress}%`}
      </text>
    </svg>
  );
};

export default CircularProgress;

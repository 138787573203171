// import '../../styles/pages/question.scss';
import React, {memo, useState} from 'react';

import {
    LoadingGif,
    starComment
} from "../../images";

const QType7 = ({quesKey}) => {

    const [isLoading, setLoading] = useState(true);

    setTimeout(function(){
        setLoading(false)
    }, 0)

    // if(isLoading){
    //     return (
    //         <div className="loadWrap loadWrapQuiz loadWrapQuestion">
    //             <div className="loadWrapLogo">
    //                 <div className="loadWrapText">
    //                     <img width={16} src={LoadingGif} alt=""/>
    //                     {/* Loading.. */}
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }

    return (
        <>
            <div className='question active' >
                {/* <p className="questionName">
                    {qKey.name}
                </p> */}
                {
                    quesKey.comments.map( (answKey, index2) => (
                        <div className={`questionComment wow delay${index2}1 ${isLoading ? '' : 'animate'}`} key={index2}>
                            <div className="questionCommentBox">
                                <div className="questionCommentLeft">
                                    <div className="questionCommentUser">
                                        <img src={answKey.image} alt="" />
                                    </div>
                                    <div className="questionCommentInfo">
                                        <span>{answKey.name}</span>
                                        <div className="questionStars">
                                            <img src={starComment} alt="" />
                                            <img src={starComment} alt="" />
                                            <img src={starComment} alt="" />
                                            <img src={starComment} alt="" />
                                            <img src={starComment} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <p className="questionCommentDate">
                                    {answKey.date}
                                </p>
                            </div>
                            <div className="questionCommentText">{answKey.text}</div>
                        </div>
                    ))
                }
                {
                    (quesKey.comments.length === 3) && (
                        <div className="commPadding"></div>
                    )
                }
            </div>
        </>     
    )
}

export default memo(QType7);
